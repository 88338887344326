import React, { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { DropzoneArea, DropzoneAreaProps } from 'material-ui-dropzone';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import {
  Theme,
  makeStyles,
  createTheme,
  ThemeOptions,
  ThemeProvider,
} from '@material-ui/core/styles';
import customTheme from '../../theme';

const MAX_FILE_SIZE = 52428800;

const DropZoneThemeOverride = createTheme({
  ...customTheme,
  overrides: {
    MuiDropzonePreviewList: {
      imageContainer: {
        '@media (max-width: 500px)': {
          padding: '0px !important',
        },
      },
      image: {
        width: 'auto',
        height: 'auto',
        maxWidth: '90%',
        '@media (max-width: 500px)': {
          padding: 0,
          maxWidth: '100%', // Cambiar maxWidth bajo los 500px
        },
      },
    },
  },
} as ThemeOptions);

export interface IDocumentDropZoneAreaFieldProps extends DropzoneAreaProps {
  name: string;
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    textContainer: {
      display: 'none',
    },
    dropzoneParagraph: {
      paddingTop: 40,
    },
    previewGridItem: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    previewGridContainer: {
      alignContent: 'center',
      alignItems: 'center',
      justifyContent: 'center',
    },
    customDropzone: {
      '& p': {
        fontSize: '17px',
        margin: '30px 18% 30px 18%',
        [theme.breakpoints.down(501)]: {
          margin: '30px 18% 5px 18%',
          fontSize: 13,
          padding: '0 5%',
          marginBottom: '40px',
        },
      },
    },
  };
});

const DocumentDropZoneAreaField: React.FC<IDocumentDropZoneAreaFieldProps> = (props) => {
  const classes = useStyles();
  const [file, setFile] = useState<File | null>(null);

  const { name, ...rest } = props;

  const { setFieldValue, values } = useFormikContext<{ [name: string]: File }>();

  useEffect(() => {
    const currentFile = values[name];
    if (currentFile) {
      setFile(currentFile);
    }
  }, []);

  useEffect(() => {
    setFieldValue(name, file);
  }, [file]);

  const getFileLimitExceedMessage = (): string => {
    return 'Solo se puede incluir un archivo.';
  };

  const getFileAddedMessage = (fileName: string): string => {
    return `Archivo ${fileName} añadido correctamente.`;
  };

  const getFileRemovedMessage = (fileName: string): string => {
    return `Archivo ${fileName} eliminado correctamente.`;
  };

  const getDropRejectMessage = (rejectedFile: File): string => {
    return `El archivo ${rejectedFile.name} no es válido. Sólo se permiten formatos de imágen (JPG, PNG) y peso máximo: 10MB`;
  };

  const getDropzoneText = (): string => {
    if (file) {
      return 'Haz click para seleccionar una imagen o arrastra la foto aquí';
    }
    return 'Haz click para seleccionar una imagen o arrastra la foto aquí';
  };

  const getDropzoneParagraphClass = (): string => (file ? '' : classes.dropzoneParagraph);

  const getIcon = (): JSX.Element => (file ? () => null : PhotoCamera);

  const onChange = (loadedFiles: File[]): void => {
    setFile(loadedFiles[0] || null);
  };

  return (
    <ThemeProvider theme={DropZoneThemeOverride}>
      <DropzoneArea
        initialFiles={values[name] ? [values[name]] : []}
        filesLimit={1}
        acceptedFiles={['image/jpeg', 'image/png']}
        Icon={getIcon()}
        maxFileSize={MAX_FILE_SIZE}
        dropzoneText={getDropzoneText()}
        dropzoneClass={classes.customDropzone}
        previewGridProps={{
          container: {},
          item: {
            sm: 8,
          },
        }}
        previewGridClasses={{
          container: classes.previewGridContainer,
          item: classes.previewGridItem,
        }}
        dropzoneParagraphClass={getDropzoneParagraphClass()}
        getFileLimitExceedMessage={getFileLimitExceedMessage}
        getFileAddedMessage={getFileAddedMessage}
        getFileRemovedMessage={getFileRemovedMessage}
        getDropRejectMessage={getDropRejectMessage}
        onChange={onChange}
        {...rest}
      />
    </ThemeProvider>
  );
};

export default DocumentDropZoneAreaField;
