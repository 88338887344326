import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import useStyles from './DropDocument.styles';
import frontal from '../../../../../../content/assets/icons/DNI/CI_anverso.png';
import DatePickerField from '../../../../Common/DatePickerField';
import DocumentDropZoneAreaField from '../../../../Common/DocumentDropzoneArea';
import Divider from '@material-ui/core/Divider';
import { ICountryData } from '../../../../../types';
import {
  Typography,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

interface DropDocumentProps {
  idExpirationSection?: boolean;
}

const messagesForForeigns: string[] = [
  'En este caso, adjunta tu cédula vencida y termina el proceso de registro. Luego, nuestro equipo de cumplimiento se contactará contigo para solicitar dos documentos: ',
  'Comprobante de solicitud de permanencia vigente',
  ', emitido por el Departamento de Extranjería de Chile.',
  'Pasaporte Vigente.',
];

const messageForChilean: string[] = [
  'En este caso, adjunta tu cédula vencida y termina el proceso de registro. Luego, envíanos a cumplimiento@currencybird.cl tu pasaporte.',
];
const DropDocument: React.FC<DropDocumentProps> = (idExpirationDate) => {
  const classes = useStyles();
  const formikContext = useFormikContext<{
    idExpirationDate: string;
    nationality: ICountryData;
    externalReport: {
      expirationDate: string;
    };
    nationalityType: string;
  }>();

  console.log('Contexto', formikContext);
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Los meses van de 0 a 11, así que sumamos 1

  const [currentYearStr, currentMonthStr] = [
    currentYear.toString(),
    currentMonth.toString().padStart(2, '0'),
  ];

  const currentDateStr = `${currentYearStr}-${currentMonthStr}`;

  const isIdExpirationDateValid = formikContext.values.idExpirationDate < currentDateStr;

  const showMessageChilean =
    ((formikContext.values.nationality && formikContext.values.nationality?.isoCode == 'CL') ||
      (formikContext.values.nationalityType && formikContext.values.nationalityType == 'C')) &&
    isIdExpirationDateValid;

  const showMessageForeign =
    ((formikContext.values.nationality && formikContext.values.nationality?.isoCode !== 'CL') ||
      (formikContext.values.nationalityType && formikContext.values.nationalityType == 'E')) &&
    isIdExpirationDateValid;

  const isEmpty = Object.keys(formikContext.values.externalReport).length === 0;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      let currentRoute = window.location.pathname;
      window.addEventListener('popstate', (event) => {
        currentRoute = window.location.pathname;
      });
      const enviarRutaAGTM = () => {
        if (window.dataLayer) {
          window.dataLayer.push({
            event: 'gatsby-route-change',
            ruta: currentRoute,
          });
        }
      };

      if (currentRoute !== '/signup-form/upload-document-front') {
        history.pushState({}, '', '/signup-form/upload-document-front');
        enviarRutaAGTM();
      }
    }
  }, []);

  return (
    <Container maxWidth="md">
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle></DialogTitle>
        <DialogContent style={{ color: '#074F71' }}>
          Te pedimos este documento para cumplir con los requisitos establecidos en la ley Nº 19.913
          que crea la Unidad de Análisis Financiero, y así garantizar el cumplimiento de las normas
          que nos resultan aplicables.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      <Grid className={classes.formProperties} container spacing={3}>
        <Typography variant="h4" className={classes.title}>
          <b>Adjunta tu cédula de identidad chilena</b>
          {/* <IconButton onClick={handleOpenModal}>
            <InfoIcon />
          </IconButton> */}
        </Typography>
        <p className={classes.subtitle}>La información debe estar perfectamente legible.</p>
        <Grid item xs={12}>
          <Card className={classes.card} variant="outlined">
            <CardContent className={classes.cardContent}>
              <Grid container>
                <Grid item xs={12} sm={12} className={classes.dniContainer}>
                  <p className={classes.cardtitle}>Frente documento identidad</p>
                  <DocumentDropZoneAreaField
                    name="documentFront"
                    getFileLimitExceedMessage={() =>
                      'Solo se puede incluir un archivo. Para subir parte trasera del documento, por favor avanzar al paso siguiente.'
                    }
                  />
                </Grid>
                <Grid item xs={6} sm={6} className={classes.dniContainer}>
                  <p className={classes.dniInfo}>Formato JPG / PNG</p>
                  <p className={classes.dniInfo}>Peso máximo 50 MB</p>
                </Grid>
                <Grid item xs={6} sm={6} className={classes.dniContainer}>
                  <img id="dni-reference-img" src={frontal} />
                </Grid>
                <div>
                  <p style={{ textAlign: 'center', color: '#f8b032', fontWeight: 'bold' }}>
                    ¿Por qué te pedimos este documento?
                  </p>
                  <p style={{ color: '#084f71', fontStyle: 'oblique' }}>
                    Te pedimos este documento para cumplir con los requisitos establecidos en la ley
                    Nº 19.913 que crea la Unidad de Análisis Financiero, y así garantizar el
                    cumplimiento de las normas que nos resultan aplicables.
                  </p>
                </div>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        {!formikContext.values.externalReport.expirationDate && (
          <Grid item xs={12}>
            <Grid>
              <DatePickerField
                name="idExpirationDate"
                label="Fecha de vencimiento del documento"
                placeholder="Fecha de vencimiento del documento"
              />
            </Grid>
          </Grid>
        )}
        {showMessageChilean && (
          <div>
            <Grid container spacing={0}>
              <Grid item sm={1} md={8}>
                <h2 className={classes.question}>¿Tienes tu cédula vencida?</h2>
              </Grid>
            </Grid>
            <div className={classes.foreignMessages}>
              <p>
                {messageForChilean[0]}
                <br />
              </p>
              <Divider className={classes.divider} />
            </div>
          </div>
        )}

        {showMessageForeign && (
          <div>
            <Grid container spacing={0}>
              <Grid item sm={1} md={8}>
                <h2 className={classes.question}>¿Tienes tu cédula vencida?</h2>
              </Grid>
            </Grid>

            <div className={classes.foreignMessages}>
              <p>
                {messagesForForeigns[0]}
                <br />
                <div className={classes.foreignMessages}>
                  a. <strong>{messagesForForeigns[1]}</strong> {messagesForForeigns[2]}
                  <br />
                  <br />
                  b. <strong>{messagesForForeigns[3]}</strong>
                </div>
              </p>
              <Divider className={classes.divider} />
            </div>
          </div>
        )}
      </Grid>
    </Container>
  );
};

export default DropDocument;
