import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import useStyles from './DropDocument.styles';
import reverso from '../../../../../../content/assets/icons/DNI/CI_reverso.png';
import DocumentDropZoneAreaField from '../../../../Common/DocumentDropzoneArea';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@material-ui/icons/KeyboardArrowUpRounded';
import Tooltip from '@material-ui/core/Tooltip';

const DropDocument: React.FC = () => {
  const classes = useStyles();
  const [showMessages, setShowMessages] = useState(false);
  const [image, setImage] = useState('down');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      let currentRoute = window.location.pathname;
      window.addEventListener('popstate', (event) => {
        currentRoute = window.location.pathname;
      });
      const enviarRutaAGTM = () => {
        if (window.dataLayer) {
          window.dataLayer.push({
            event: 'gatsby-route-change',
            ruta: currentRoute,
          });
        }
      };

      if (currentRoute !== '/signup-form/upload-document-back') {
        history.pushState({}, '', '/signup-form/upload-document-back');
        enviarRutaAGTM();
      }
    }
  }, []);

  const handleChange = () => {
    setShowMessages((prev) => !prev);
    if (image == 'down') {
      setImage('up');
    } else {
      setImage('down');
    }
  };

  return (
    <Container maxWidth="md">
      <Grid className={classes.formProperties} container spacing={3}>
        <h4 className={classes.title}></h4>
        <p className={classes.subtitle}></p>
        <Grid item xs={12}>
          <Card className={classes.card} variant="outlined">
            <CardContent className={classes.cardContent}>
              <Grid container>
                <Grid item xs={12} sm={12} className={classes.dniContainer}>
                  <p className={classes.cardtitle}>Revés documento identidad</p>
                  <DocumentDropZoneAreaField name="documentBack" />
                </Grid>
                <Grid item xs={6} sm={6} className={classes.dniContainer}>
                  <p className={classes.dniInfo}>Formato JPG / PNG</p>
                  <p className={classes.dniInfo}>Peso máximo 50 MB</p>
                </Grid>
                <Grid item xs={6} sm={6} className={classes.dniContainer}>
                  <img id="dni-reference-img" src={reverso} />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        {/* <Grid item xs={12}>
                <Grid container spacing={0}>
                  <Grid onClick={handleChange} container spacing={0}>
                    <Grid item md={9}>
                      <h2 className={classes.questionBack}>¿Por qué te solicitamos este documento?</h2>
                    </Grid>
                      <Tooltip title="Presiona para ver más detalle">
                        <Grid className={classes.svgBack} item md={3}>
                          {image == 'down' ? (
                            <KeyboardArrowDownRoundedIcon />
                          ) : (
                            <KeyboardArrowUpRoundedIcon />
                          )}
                        </Grid>
                      </Tooltip>
                  </Grid>
                  {showMessages &&
                   <div className={classes.foreignMessages}>
                   <p>
                   Te solicitamos este documento para cumplir con los requisitos establecidos en la ley Nº 19.913 que crea la Unidad de Análisis Financiero, y así garantizar el cumplimiento de las normas que nos resultan aplicables.<br />
                   </p>
                 </div>
                  }
                </Grid>

        </Grid> */}
      </Grid>
    </Container>
  );
};

export default DropDocument;
